.GD-button {
   background-color: #C65548;
   color: #fff;
   box-shadow: 0 2px 6px 0 rgba(0,0,0,.25);
}

.GD-button:hover {
   background-color: #9d4238;
}

.GD-button:focus {
   background-color: #722d25;
}
.GD-button:active {
   background-color: #722d25;
}

.GD-h4 {
   text-transform: uppercase;
   font-weight: 500;
   color: #C65548;
   margin-top: 2em;
}

@media (min-width: 688px) {

   .GD-h4 {
      text-align: center;
   }
}