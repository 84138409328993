body {
  width: 100%;
  height: 100%;
}

main {
  width: 100vw;
  max-width: 800px;
  padding: 0 8vw 0 8vw;
  margin: auto;
}

button {
   vertical-align: middle;
   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   user-select: none;
   font-size: 1rem;
   line-height: 1.5;
   border-radius: 5px;
   border: none;
   transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

button:focus {
   outline: none;
   box-shadow: 0 0 0 0.2rem rgba(38,143,255,.5);
}

footer {
   margin: 80px 0 0 0;
   padding: 64px 8vw 32px 8vw;
   background-image: url("../assets/footer-hills.svg");
   background-repeat: no-repeat;
   background-size: cover;
}

h1, h2, h3 {
  font-family: sofia-pro, sans-serif;
  font-style: normal;
}

h1 {
  font-weight: 600;
  font-size: 2em;
}

h2 {
  font-weight: 500;
  font-size: 1.8em;
}

h3 {
  font-weight: 400;
  font-size: 1.5em;
}

h4 {
   font-weight: 300;
   font-size: 1em;
}

hr {
   width: 50%;
   margin-bottom: 32px;
}

p {
   font-family: 'Helvetica Neue', sans-serif;
   font-weight: 400;
   font-style: normal;
   font-size: 1em;
   line-height: 1.5em;
   letter-spacing: 1px;
 }
 
.bodyContent {
  text-align: center;
  margin-bottom: 64px;
}

.banner {
  margin: 32px 0 64px 0;
  text-align: left;
}

.bannerText {
   margin: 64px 8% 32px 8%;
}

.callToAction {
   width: 70%;
   margin: auto 0 64px auto;
   display: block;
}

.callToAction h3 {
   text-align: right;
   margin-bottom: 32px;
}

.callToAction button {
   text-transform: uppercase;
   padding: .65em 1.15em;
   font-family: Helvetica, sans-serif;
   font-weight: 800;
   margin: 0 0 0 auto;
}

.cards {
   display: flex;
   flex-direction: column;
}

.card {
   text-align: left;
   margin-bottom: 16px;
   flex-grow: 1;
}

.catchphrase {
  width: 100%;
}

.catchphrase h1 {
  font-size: 2.2em;
}

.contactInfo section {
   margin: 32px auto;
}

.top-nav {
  width: 100%;
}

.nav {
  text-align: center;
  background: none;
}

.navbar-nav {
   padding: 2em;
   gap: 1em;
   border-radius: 8px;
   background-color: white;
   filter: drop-shadow(0 10px 8px #dddddd);
}

.nav a {
   font-family: 'Helvetica Neue', sans-serif;
   font-weight: 500;
   color: black;
}

.nav .current-page {
   border-bottom: 2px solid #d32e27;
   color: #d32e27;
}

.navimg {
   display: inline-block;
   height: 30px;
   width: auto;
   margin: 8px 0 0 8px;
}

.navbar-toggler {
   border: none;
}

.otherServices {
   margin-bottom: 64px;
}

.otherServices h3 {
   margin-bottom: 1em;
}

.previewService {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: flex-start;
   text-align: left;
   padding: 0;
   margin-bottom: 64px;
}

.previewService section {
   margin: auto;
}

.previewService h3 {
   font-size: 1.4em;
}

.previewService img {
   width: 100%;
   height: auto;
   display: block;
   margin: 64px auto 32px;
}

.serviceDescription {
   text-align: left;
   padding: 0;
   margin-bottom: 64px;
 }

 .serviceDescription h3 {
   font-weight: 600;
   text-align: center;
   margin-bottom: 1em;
 }

.footerLinks {
   list-style: none;
   padding: 0;
}

.footerLinks li {
   margin: 10px 0;
}

.footerServices {
   margin-top: 1.5rem;
   font-weight: 500;
}

.copyright {
   text-align: center;
   margin: 32px auto 0 auto;
   font-size: 1em;
}

/* Tablet size */
@media (min-width: 688px) {
   
   main {
      max-width: 60rem;
   }

   .bannerText {
      margin: 64px 8% 32px 8%;
   }

   .callToAction {
      width: 100%;
      margin: auto auto 64px;
      display: block;
   }
   
   .callToAction h3 {
      text-align: center;
      margin-bottom: 32px;
   }
   
   .callToAction button {
      text-transform: uppercase;
      padding: .65em 1.15em;
      font-family: Helvetica, sans-serif;
      font-weight: 800;
      margin: auto;
   }

   .cards {
      flex-direction: row;
      justify-content: space-between;
   }

   .card {
      max-width: 48%;
   }

   .catchphrase {
      width: 450px;
    }

   .catchphrase h1 {
      font-size: 2.4em;
   }

   .navbar-nav {
      background: none;
   }

   .previewService section {
      display: grid;
      grid-template: 100% / 1fr 1fr;
      width: 100%;
      margin-bottom: 32px;
   }

   .previewService section .text {
      display: block;
      width: 80%;
      margin: auto;
      padding: 0 16px;
      justify-self: end;
   }
    
    .previewService h3 {
       font-size: 1.4em;
    }

   .previewService img {
      width: 100%;
      height: auto;
      margin: auto;
      padding: 16px;
   }

}

/* Desktop size */
@media (min-width: 992px) {

   main {
      max-width: 70rem;
    }

    .catchphrase {
       width: 1000px;
    }

    .top-nav {
       max-width: 80rem;
       margin: auto;
    }

    .navbar-nav {
       margin: 0 0 0 auto;
    }

}

/* Large monitor size */
@media (min-width: 1312px) {

   main {
      max-width: 85rem;
    }

    .top-nav {
       max-width: 95rem;
       margin: auto;
    }

    .navbar-nav {
       margin: 0 0 0 auto;
    }

}

@font-face {
   font-family: 'Helvetica Neue';
   src: local('Helvetica Neue Roman'), local('Helvetica-Neue-Roman'),
       url('../fonts/HelveticaNeueCyr-Roman/HelveticaNeueCyr-Roman.woff2') format('woff2'),
       url('../fonts/HelveticaNeueCyr-Roman/HelveticaNeueCyr-Roman.woff') format('woff'),
       url('../fonts/HelveticaNeueCyr-Roman/HelveticaNeueCyr-Roman.ttf') format('truetype');
 }

/* cool stuff that I need to understand
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #2b3428;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/