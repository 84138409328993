.PH-button {
   background-color: #85b54c;
   color: #fff;
   box-shadow: 0 2px 6px 0 rgba(0,0,0,.25);
}

.PH-button:hover {
   background-color: #759b47;
}

.PH-button:focus {
   background-color: #587a2e;
}
.PH-button:active {
   background-color: #587a2e;
   ;
}

.PH-h4 {
   text-transform: uppercase;
   font-weight: 500;
   color: #85b54c;
   margin-top: 2em;
}

@media (min-width: 688px) {

   .PH-h4 {
      text-align: center;
   }
}