.WD-button {
   background-color: #56B2B2;
   color: #fff;
   box-shadow: 0 2px 6px 0 rgba(0,0,0,.25);
}

.WD-button:hover {
   background-color: rgb(68, 158, 158);
}

.WD-button:focus {
   background-color: rgb(47, 121, 121);
}
.WD-button:active {
   background-color: rgb(47, 121, 121);
}

.WD-h4 {
   text-transform: uppercase;
   font-weight: 500;
   color: #56B2B2;
   margin-top: 2em;
}

@media (min-width: 688px) {

   .WD-h4 {
      text-align: center;
   }
}